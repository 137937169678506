
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import { LoginServices } from './services/LoginServices';
// import { LocalizationClient } from './services/Services';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import utils from './utils';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    loaded: boolean = false;

    created() {
        utils.assignViewWithServices(this);
        store.state.loggedUser = StorageServices.getLoggedUser();
        store.state.selectedLanguage = store.state.loggedUser.preferredCulture;

        this.init();
    }

    init(){
        // Promise.all([
        //     LocalizationClient.getAppDictionary(),
        //     LocalizationClient.getEnabledLanguages(),
        // ])
        // .then( x => {
        //     store.state.consts.localizedValues = x[0];
        //     store.state.consts.enabledLanguages = x[1];
        //     this.loaded = true;

        //     this.checkLogin();
        // })
        this.loaded = true;
    }

    checkLogin(){
        var token = StorageServices.getLoginToken();
        var refreshToken = StorageServices.getLoginRefreshToken();

        LoginServices.LoginCallback(token, refreshToken);
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }
}


import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import UpdatePasswordModal from './modals/updatePasswordModal.vue';

@Options({
  components: { },
})
export default class DriverNavbar extends Vue {

    created() {
    }
    
    updatePwd() {
        this.$opModal.show(UpdatePasswordModal, {});
    }
    
    logout() {
        LoginServices.logout();
    }

}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal formContainer d-flex justify-content-between align-items-center flex-column" }
const _hoisted_2 = { class: "page_padding text-center w-100" }
const _hoisted_3 = { class: "page_padding d-flex w-100 mt-4" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_localized_text, {
      type: "h3",
      localizedKey: "Cambia Password",
      text: "Cambia Password",
      class: "text-center darkGreen text-uppercase fw_700 mb-5"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_form_field, {
        type: "password",
        placeholderKey: "Nuova Password",
        placeholderValue: "Nuova Password",
        modelValue: _ctx.newPassword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn btn-secondary text-center justify-content-center w-50 me-1",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Annulla",
          text: "Annulla"
        })
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary text-center justify-content-center w-50 ms-1",
        disabled: !_ctx.newPassword,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePassword()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Conferma",
          text: "Conferma"
        })
      ], 8, _hoisted_4)
    ])
  ]))
}
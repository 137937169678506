import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/homepage',
            name: 'homepage',
            component: () => import("./views/homepage.vue")
        },
    ];
};
export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}

export class LocalizedValue {
    values : { [key: string]: string; };
    constructor() {
        this.values = {};
    }
}

export class LocalizedEntity extends LocalizedValue {
    key : string;
    identifier : string;
    constructor() {
        super();
        this.key = "" ;
        this.identifier = "" ;
    }
}

export class FakeConfiguration {
    propertyName: string;
    propertyType: string;
    isMandatory: boolean;
    constructor() {
        this.propertyName = "";
        this.propertyType = "";
        this.isMandatory = false;
    }
}

export class DayOfWeek {
    text: string;
    value: number;
    constructor(){
        this.text = "";
        this.value = -1;
    }
}

export class EditFile {
    file: File;
    fileIdentifier: string;
    constructor(){
        this.file = null;
        this.fileIdentifier = "";
    }
}

export class ListStringAsObj {
    value: string;
    constructor(actualValue: string) {
        this.value = actualValue;
    }
}

export class Notification {
    title: string;
    text: string;
    constructor(){
        this.title = "";
        this.text = "";
    }
}

export let Roles = {
    Admin: "admin",
    Employee: "employee",
    CompanyManager: "companymanager",
    InternalUser: "internaluser",
}
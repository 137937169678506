
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditNoteModal extends Vue {

    @Prop({
        default: () => new OM.NoteVm()
    }) note: OM.NoteVm;

    @Prop({
        default: true
    }) showTypology: boolean;
    
    @Prop() callback: any;

    isPrivate: boolean = false;
    noteText: string = "";

    created() {
        this.isPrivate = this.note.type == OM.NoteType.Private;
        this.noteText = this.note.notes;
    }

    get saveDisabled() {
        return !this.noteText;
    }

    save() {
        this.callback(this.isPrivate, this.noteText);
    }

}

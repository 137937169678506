import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/shifts',
            name: 'shifts',
            component: () => import("./views/shifts/shifts.vue")
        },
        //employees
        {
            path: '/employees',
            name: 'employees',
            component: () => import("./views/employee/employees.vue")
        },
        {
            path: '/employee-edit/:employeeIdentifier?',
            name: 'employee edit',
            component: () => import("./views/employee/editEmployee.vue")
        },
        //van
        {
            path: '/fleet',
            name: 'fleet',
            component: () => import("./views/van/vanList.vue")
        },
        {
            path: '/fleet/van-edit/:vanIdentifier?',
            name: 'van edit',
            component: () => import("./views/van/editVan.vue")
        },
        //headquarters
        {
            path: '/headquarters',
            name: 'headquarters',
            component: () => import("./views/headquarter/headquartersList.vue")
        },
        //configuration
        {
            path: '/configuration',
            name: 'configuration',
            component: () => import("./views/configuration/configuration.vue")
        },
        {
            path: '/configuration/employees',
            name: 'employees configuration',
            component: () => import("./views/configuration/employeesConfiguration.vue")
        },
        {
            path: '/configuration/van',
            name: 'van configuration',
            component: () => import("./views/configuration/vanConfiguration.vue")
        },
        {
            path: '/configuration/headquarter',
            name: 'headquarter configuration',
            component: () => import("./views/configuration/headquarterConfiguration.vue")
        },
        {
            path: '/configuration/workshift-types',
            name: 'workshift type configuration',
            component: () => import("./views/configuration/workShiftTypeConfiguration.vue")
        },
    ];
};

import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { createPreviewFromImage } from '@/utils';
import WithModel from './standard/formFieldPlugin/mixins/withModel';

@Options({
    components: { },
})
export default class SelectFile extends mixins(WithModel) {
    
    @Prop({
        default: ""
    }) imageUrl: string;

    @Prop({
        default: true
    }) onlyImage: boolean;

    loadedFilePublicUrl = "";

    created(){
    }

    get publicUrl(){
        if(this.loadedFilePublicUrl)
            return this.loadedFilePublicUrl;

        return this.imageUrl;
    }

    async imageChangedTemp(file: File){
        if(!file)
            return;

        if(this.onlyImage)
		    this.loadedFilePublicUrl = await createPreviewFromImage(file);
            
        this.emitModel(file);
	}
}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AttachmentClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class DocumentEditModal extends Vue {

    @Prop({
        default: () => new OM.UrlAttachment()
    }) document: OM.UrlAttachment;

    @Prop({
        default: true
    }) editMode: boolean;
    
    @Prop() callback: any;

    created() {
    }

    get fileName() {
        var startPoint = this.document.fileName.indexOf("_");
        return this.document.fileName.slice(startPoint + 1);
    }

    get isPDF() {
        return this.document.fileName.indexOf("pdf") > -1;
    }

    onFileSelect(ev){
        let file = ev.target.files[0];
        this.callback(file);
    }     

    editDocument() {
        (<HTMLInputElement>this.$refs.selectFile).click();
    }

    downlaodFile(){
        AttachmentClient.downloadFile(this.document)
        .then(x => {
            const url = window.URL.createObjectURL( (x as any).blob());

            // window.open(<any>x, "_blank");
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.document.fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
            
            // window.open(<any>x, "_self");
            // console.log(x)
        })
    }

}

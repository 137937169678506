import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import begin from '@/views/begin';
import admin from '@/views/admin';
import driver from '@/views/driver';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/',
        component: () => import("../views/adminLayout.vue"),
        beforeEnter: (to, from) => {
            if(!store.state.loggedUser.identifier)
                return false
        },
        children: [
            ...admin.routes(),
        ]
    },
    {
        path: '/',
        component: () => import("../views/driverLayout.vue"),
        beforeEnter: (to, from) => {
            if(!store.state.loggedUser.identifier)
                return false
        },
        children: [
            ...driver.routes()
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
        window.scrollTo({ 
            left: savedPosition ? savedPosition.left : 0, 
            top: savedPosition ? savedPosition.top : 0,
            behavior: <any>'instant' //⚡
        });
    }
})

export default router
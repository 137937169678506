
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({
    components: {}
})
export default class App extends Vue {

    mounted(){
    }
    
    @Watch("$store.state.notification")
    onNotificationChanged(){
        if(!store.state.notification){
            return;
        }

        setTimeout(() => {
            store.state.notification = null;
        }, 3000);
    }

}

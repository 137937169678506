import * as VM from '@/viewModel';
import router from "./router";
import store from './store';

let viewWithServices = null;

export default {

    getTotal(property: string, list: any[], startSum: number = 0){
        return list.reduce((partialSum, a) => partialSum + a[property], startSum);
    },

    JsonClone(item){
        return JSON.parse(JSON.stringify(item));
    },

    assignViewWithServices(viewWithServicesComponent){
        viewWithServices = viewWithServicesComponent;
    },

    getViewWithServices(){
        return viewWithServices;
    },

    routerReplaceAndPush(replaceUrl: string, pushUrl: string){
        router.replace(replaceUrl)
        .then( () => {
            router.push(pushUrl);
        }).catch( () => {
            console.error("router replace error!");
        })
    },

    removeSpaces(text: string) : string {
        if(!text)
            return text;

        var temp = text.trim();

        while(temp.indexOf("  ") != -1)
            temp = temp.replace("  ", " ").trim();

        return temp;
    },

    download(filename, text, type) {
        let blob = new Blob([text], { type })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click();
    },

    getUniversalISOStringDate(input: Date): any {
        var data = new Date(input);
        data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

        return data.toISOString();
    },

    getUniversalDate(input: any): Date {
        var data = new Date(input);
        data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

        return data;
    },

    removeBodyVerticalScroll() {
        document.getElementById("body").style.overflowY = "hidden";
    },

    addBodyVerticalScroll() {
        document.getElementById("body").style.overflowY = "auto";
    },

    decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    },

    getFileFromUri(uri: string): Promise<File>{
        if(uri.indexOf('://') === -1) 
            uri = "file://" + uri;

        let prom = new Promise<File>((resolve, reject) => {
            (<any>window).resolveLocalFileSystemURL(uri, (fileEntry) => {
                fileEntry.file((resFile) => {
                    resolve(resFile);
                });
            }, err => {
                console.log(err)
            })
        });

        return prom;
    },

    createPreviewFromImage(file) : Promise<string> {
        let prom = new Promise<string>((resolve, reject) => {
            if(!file){
                resolve("");
                return;
            }

            if (!file.type.match('image.*')) {
                alert('Select an image');
                return;
            }

            if(!window.cordova){
                var url = URL.createObjectURL(file);
                resolve(url);
                return;
            }
            
            var reader = new FileReader();

            reader.onload = (e:any) => {
                resolve(e.target.result);
            }
            reader.readAsDataURL(file);
        })

        return prom;
    },

    async downloadPdf(endpoint: string, estimationIdentifier: string, fileName: string) {
        const response = await fetch(endpoint + "?estimationIdentifier=" + estimationIdentifier);
        console.log("response");
        console.log(response);
        const blob = await response.blob();
    
        // Crea un URL per il blob
        const url = window.URL.createObjectURL(blob);
    
        // Crea un elemento 'a' e simula un clic per scaricare il file
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        
        // Rilascia l'URL del blob
        window.URL.revokeObjectURL(url);
    }
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}

export function showNotification(title: string, text: string) {
    var ris = new VM.Notification();
    ris.title = title;
    ris.text = text;
    store.state.notification = ris;
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aedb695e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "input_title"
}
const _hoisted_3 = ["value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_select_field = _resolveComponent("select-field")!
  const _component_select_field_string = _resolveComponent("select-field-string")!
  const _component_checkbox_field = _resolveComponent("checkbox-field")!
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type != 'localized' && _ctx.type != 'localized-textarea' && _ctx.labelKey
            && !_ctx.fileModeIcon && _ctx.type != 'checkbox')
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createVNode(_component_localized_text, {
            class: "fev_text5 fw_600",
            localizedKey: _ctx.labelKey,
            text: _ctx.labelValue,
            prevText: _ctx.prevText,
            afterText: _ctx.localAfterText,
            object: _ctx.labelObject
          }, null, 8, ["localizedKey", "text", "prevText", "afterText", "object"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'select')
      ? (_openBlock(), _createBlock(_component_select_field, {
          key: 1,
          tabIndex: _ctx.tabIndex,
          labelKey: _ctx.labelKey,
          labelValue: _ctx.labelValue,
          required: _ctx.required,
          disabled: _ctx.readonly,
          placeholderKey: _ctx.placeholderKey,
          placeholderValue: _ctx.placeholderValue,
          placeholderAfterText: _ctx.placeholderAfterText,
          placeholderPrevText: _ctx.placeholderPrevText,
          prevText: _ctx.prevText,
          afterText: _ctx.localAfterText,
          "onUpdate:modelValue": _ctx.emitModel,
          modelValue: _ctx.modelValue,
          list: _ctx.list,
          showSearch: _ctx.showSearch
        }, null, 8, ["tabIndex", "labelKey", "labelValue", "required", "disabled", "placeholderKey", "placeholderValue", "placeholderAfterText", "placeholderPrevText", "prevText", "afterText", "onUpdate:modelValue", "modelValue", "list", "showSearch"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'select-string')
      ? (_openBlock(), _createBlock(_component_select_field_string, {
          key: 2,
          tabIndex: _ctx.tabIndex,
          labelKey: _ctx.labelKey,
          labelValue: _ctx.labelValue,
          required: _ctx.required,
          placeholderKey: _ctx.placeholderKey,
          placeholderValue: _ctx.placeholderValue,
          placeholderAfterText: _ctx.placeholderAfterText,
          placeholderPrevText: _ctx.placeholderPrevText,
          prevText: _ctx.prevText,
          afterText: _ctx.localAfterText,
          "onUpdate:modelValue": _ctx.emitModel,
          modelValue: _ctx.modelValue,
          list: _ctx.list,
          showSearch: _ctx.showSearch
        }, null, 8, ["tabIndex", "labelKey", "labelValue", "required", "placeholderKey", "placeholderValue", "placeholderAfterText", "placeholderPrevText", "prevText", "afterText", "onUpdate:modelValue", "modelValue", "list", "showSearch"]))
      : (_ctx.type == 'checkbox')
        ? (_openBlock(), _createBlock(_component_checkbox_field, _mergeProps({ key: 3 }, _ctx.$props, {
            onValueChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('valueChanged', $event)))
          }), null, 16))
        : (_ctx.type == 'textarea')
          ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
              key: 4,
              class: "w-100 p-3",
              value: _ctx.localValue,
              rows: "6",
              maxlength: _ctx.maxlength,
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event.target.value))
            }, null, 40, _hoisted_3)), [
              [_directive_localizedPlaceholder, { key: _ctx.placeholderKey, value: _ctx.placeholderValue }]
            ])
          : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.inputComponent), _mergeProps({
              key: 5,
              "onUpdate:modelValue": [
                _ctx.emitModel,
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue) = $event))
              ],
              modelValue: _ctx.localValue,
              required: _ctx.required,
              prefix: _ctx.localPrefix,
              "onUpdate:prefix": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localPrefix) = $event)),
              onChangedPrefix: _ctx.updatePrefix
            }, _ctx.$props), null, 16, ["onUpdate:modelValue", "modelValue", "required", "prefix", "onChangedPrefix"]))
  ]))
}

import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DocumentEditModal from './modals/documentEditModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: { },
})
export default class DocumentManagement extends Vue {

    @Prop({
        default: ""
    }) sectionTitle: string;

    @Prop({
        default: true
    }) onlyImage: boolean;

    @Prop({
        default: () => []
    }) urlAttachments: OM.UrlAttachment[];

    newJobContractFile: File = null;

    created(){}

    @Watch('newJobContractFile')
    updateFile() {
        if(!this.newJobContractFile)
            return;

        this.$emit('newFileAdded', this.newJobContractFile);
    }

    openEditModal(attachment: OM.UrlAttachment) {
        this.$opModal.show(DocumentEditModal, {
            document: attachment,
            callback: (file: File) => {
                var vm = new VM.EditFile();
                vm.file = file;
                vm.fileIdentifier = attachment.fileIdentifier;
                this.$emit('fileUpdated', vm);
                this.$opModal.closeLast();
            }
        })
    }

}

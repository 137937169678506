import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/logo.png'


const _withScopeId = n => (_pushScopeId("data-v-7135d572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header p-4 d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "loggedUser" }
const _hoisted_3 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "logo_image pointer",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/homepage')))
      }),
      _createElementVNode("a", {
        class: "logout_clickable w-auto",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Logout",
          text: "Logout"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$store.state.loggedUser.username), 1),
      _createVNode(_component_localized_text, {
        localizedKey: "Cambia Password",
        text: "Cambia Password",
        class: "updatePassword text-decoration-underline",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePwd()))
      })
    ])
  ], 64))
}
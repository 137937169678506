import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal formContainer d-flex justify-content-between align-items-center flex-column" }
const _hoisted_2 = { class: "page_padding text-center w-100" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "page_padding text-center w-100"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "page_padding d-flex w-100" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.img)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "mb-3",
            src: './img/' + _ctx.img
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("h3", {
        class: "fw_700 text-center darkGreen",
        innerHTML: _ctx.text
      }, null, 8, _hoisted_4)
    ]),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", {
            class: "fev_text4 text-center fs_27",
            innerHTML: _ctx.subtitle
          }, null, 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.confirmCb)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["btn btn-primary text-center justify-content-center", _ctx.denyCb && _ctx.confirmCb ? 'w-50 me-1' : 'w-75 mx-auto']),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmCb && _ctx.confirmCb(...args)))
          }, [
            _createElementVNode("span", { innerHTML: _ctx.confirm }, null, 8, _hoisted_8)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.denyCb)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass(["btn btn-secondary text-center justify-content-center", _ctx.denyCb && _ctx.confirmCb ? 'w-50 ms-1' : 'w-75 mx-auto']),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.denyCb && _ctx.denyCb(...args)))
          }, [
            _createElementVNode("span", { innerHTML: _ctx.deny }, null, 8, _hoisted_9)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}